import * as React from 'react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from '../styled/button'
import { Input } from '../styled/input'
import Dropdown from '../styled/dropdown'
import { SCREEN } from '../../styles/screens'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import axios from 'axios'
import { config } from '../../config'
import Modal from 'react-modal'
import Confirmation from './confirmationStep'
import SubscriptionCard from './subscriptionCard'
import PoweredByStripe from 'svgs/powered_by_stripe.svg'
import { getOptions } from '../../data/dictionary'
import FinalStep from './finalStep'

const COUNTRIES = getOptions('country')

// COMPONENTS

const StripeInputContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  border: var(--border-size) solid var(--primary-light);
  border-radius: var(--border-radius);
  height: calc(var(--spacing) * 14);
  padding: 0 calc(var(--spacing) * 4);
  transition: all 300ms;
  &::placeholder {
    color: var(--primary-light);
  }
  &:focus {
    border-color: var(--primary);
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type='number'] {
    -moz-appearance: textfield;
  }
`

const Container = styled.div``
const StyledButton = styled(Button)`
  margin-top: calc(var(--spacing) * 6);
  width: 100%;
`

const StyledLabel = styled.label`
  font-weight: 500;
  display: block;
  margin: 4px;
`

const Error = styled.div`
  color: red;
`

const TwoInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    display: flex;
    flex-wrap: wrap;
  }

  > div {
    width: 45%;
    @media only screen and (max-width: ${SCREEN.MOBILE}) {
      width: 100%;
    }
  }
`
const BecomeTrusteeTwo = () => {
  const [clientSecret, setClientSecret] = useState('')
  const [errors, setErrors] = useState({})
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [cardData, setCardData] = useState({})

  const [data, setData] = React.useState({})
  const [loading, setLoading] = React.useState(false)


  const stripe = useStripe()
  const elements = useElements()

  useEffect(async () => {
    if(!elements){
      return;
    }
    const token = window.localStorage.getItem('token')
    let { client_secret } = (
      await axios.get(
        `${config.API_BASE_URL}/users/subscription/secret?token=${token}`,
      )
    ).data
    setClientSecret(client_secret)
    const cardElement = elements.getElement(CardElement)
    cardElement.on('change', function (event) {
      setCardData(event)
      if (event.complete) {
        // enable payment button
      } else if (event.error) {
        // show validation to customer
      }
    })
  }, [elements])


  const validate = () => {
    let e = {}
    if (!data.name) {
      e.name = 'Valid name is required'
    }
    if (!cardData.complete) {
      e.card = 'Invalid card information'
    }
    if (!data.country) {
      e.country = 'Required'
    }
    if (!data.city) {
      e.city = 'Required'
    }
    if (!data.address_1) {
      e.address_1 = 'Required'
    }
    setErrors(e)
    return Object.keys(e).length === 0
  }

  const validateSubscription = async setupIntent => {
    const token = window.localStorage.getItem('token')
    try {
      await axios.post(`${config.API_BASE_URL}/users/subscription/update`, {
        paymentData: { ...data },
        token,
        setupIntent,
      })
      setConfirmationModal(false)
      setSuccessModal(true)
    } catch (e) {
      alert('Oops! We were unable to charge your card.')
      setLoading(false)
    }
  }

  const handleSubmit = async event => {
    // Block native form submission.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }
    setLoading(true)

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement)

    const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: data.name,
        },
      },
    })

    if (error) {
      setLoading(false)
      alert(error.message);
    } else {
      await validateSubscription(setupIntent)
      setLoading(false)
    }
  }

  const handleInputChange = e => {
    let d = { ...data }
    d[e.target.id] = e.target.value
    setData(d)
  }

  let country = null
  COUNTRIES.map(c => {
    if (data.country === c.value) {
      country = c
    }
  })

  return (
    <Container>
      <Input
        type="text"
        value={data.name}
        error={errors.name}
        id={'name'}
        onChange={handleInputChange}
        label="Name on Card"
        placeholder="Jhon Doe"
      />

      <StyledLabel>Card Information</StyledLabel>
      <StripeInputContainer>
        <CardElement
          options={{
            style: {
              base: {
                height: '30px',
                border: 'solid 1 px red',
                lineHeight: '30px',
                fontSize: '18px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      </StripeInputContainer>
      {errors.card && <Error>{errors.card}</Error>}

      <TwoInputContainer>
        <Dropdown
          value={country}
          onSelect={e => {
            setData({ ...data, country: e.value })
          }}
          error={errors.country}
          label="Country"
          id={'country'}
          placeholder="Country"
          isSearchable={false}
          keepValue={true}
          options={COUNTRIES}
        />
        <Input
          type="text"
          id="city"
          error={errors.city}
          value={data.city}
          onChange={handleInputChange}
          label="City"
          placeholder="City"
        />
      </TwoInputContainer>
      <Input
        type="text"
        id="address_1"
        error={errors.address_1}
        value={data.address_1}
        onChange={handleInputChange}
        label="Address 1"
        placeholder="Address 1"
      />
      <Input
        type="text"
        id="address_2"
        value={data.address_2}
        onChange={handleInputChange}
        label="Address 2"
        placeholder="Address 2"
      />

      <StyledButton
        onClick={() => {
          if (!validate()) {
            return
          }
          setConfirmationModal(true)
        }}
      >
        Update Card
      </StyledButton>

      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        <PoweredByStripe width={'100px'} />
      </div>

      <Modal
        isOpen={confirmationModal}
        contentLabel={'Confirm'}
        ariaHideApp={false}
        className="react_modal_centered"
        overlayClassName="react_modal_register_overlay"
      >

        <SubscriptionCard
          titles={[{ content: 'CONFIRMATION', inverted: true }]}
          onClose={() => setConfirmationModal(false)}
          isLeft={false}
        >
          <Confirmation
            loading={loading}
            paymentData={data}
            onConfirm={handleSubmit}
          />
        </SubscriptionCard>
      </Modal><Modal
        isOpen={successModal}
        contentLabel={'Success'}
        ariaHideApp={false}
        className="react_modal_centered"
        overlayClassName="react_modal_register_overlay"
      >

        <SubscriptionCard
          titles={[{ content: 'Success', inverted: true }]}
          onClose={() => setSuccessModal(false)}
          isLeft={false}
        >
          <FinalStep onFinal={()=>{
            window.location.reload();
          }}/>
        </SubscriptionCard>
      </Modal>
    </Container>
  )
}

export default BecomeTrusteeTwo

import * as React from 'react'

import Layout from 'layouts/main'
import BecomeTrusteeTwo from '../components/updateSubscription/becomeTrusteeTwo'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

let stripePromise = loadStripe('pk_live_51JkCI4L51W4ffuQ8P0WaJl6xhMv8pj4Lq1BEBEBSKFkOnS41FNszPbmxVuboGf3XzV3oJvcR8RmnAzJGcorZVT2j00NHRQlWEv')


const SubscriptionPage = () => (
  <Layout title={'Subscription'}>

    <div style={{ maxWidth: 500, margin: '0 auto' }}>
      <Elements stripe={stripePromise}>
        <BecomeTrusteeTwo />
      </Elements>
    </div>

  </Layout>
)

export default SubscriptionPage
